<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-card elevation="4" class="mt-0">
      <v-data-table
        :headers="headers"
        :items="listHubs"
        :loading="isLoadingHubs"
        :server-items-length="hubsMeta.totalItems"
        :options.sync="options"
        :items-per-page="30"
        :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
      >
        <template v-slot:item.status="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="$admin.can('hub-update')"
            small
            class="mr-2"
            @click="editToken(item)"
          >
            mdi-lock-reset
          </v-icon>
          <v-icon
            v-if="$admin.can('hub-update')"
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="$admin.can('hub-delete')"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          {{ $t("no_data_available") }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t("hubs") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <token-hubs-dialog
              v-if="$admin.can('hub-update')"
            ></token-hubs-dialog>
            <edit-hubs-dialog
              v-if="$admin.can('hub-update')"
            ></edit-hubs-dialog>
            <delete-hubs-dialog
              v-if="$admin.can('hub-delete')"
            ></delete-hubs-dialog>
          </v-toolbar>
          <v-row class="mx-2 mx-0">
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="options.search"
                :label="$t('search')"
                class=""
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                clearable
                v-model="options.type"
                :items="hubTypes"
                item-text="name"
                item-value="value"
                :label="$t('type')"
                :disabled="is_loading_hubs"
                @focus="getHubTypes"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                clearable
                v-model="options.status"
                :items="['active', 'inactive']"
                :label="$t('status')"
                :disabled="is_loading_hubs"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import { helpers } from "@/mixins/helpers";

export default {
  mixins: [helpers],
  components: {
    EditHubsDialog: () => import("./hub-dialogs/Edit.vue"),
    DeleteHubsDialog: () => import("./hub-dialogs/Delete.vue"),
    TokenHubsDialog: () => import("./hub-dialogs/Token.vue"),
  },

  watch: {
    options: {
      handler: debounce(async function () {
        this.is_loading_hubs = true;
        await this.$store.dispatch("hubs/list", this.options).then(() => {
          this.is_loading_hubs = false;
          if (this.options.page > this.hubsMeta.lastPage) {
            this.options.page = 1;
          }
        });
      }, 1000),
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      isLoadingHubs: "hubs/isLoadingHubs",
      listHubs: "hubs/listHubs",
      hubsMeta: "hubs/meta",
      hubTypes: "hubs/getTypes",
    }),
  },

  data() {
    return {
      options: {},
      is_loading_hubs: false,
      headers: [
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("status"),
          value: "status",
          width: "15%",
        },
        {
          text: this.$t("type"),
          value: "type",
          width: "15%",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
          width: "15%",
        },
      ],
    };
  },
  methods: {
    editItem(item) {
      this.$store.dispatch("hubs/openEditForm", item);
    },

    editToken(item) {
      this.$store.dispatch("hubs/openTokenForm", item);
    },

    deleteItem(item) {
      this.$store.dispatch("hubs/openDeleteForm", item);
    },

    getColor(status) {
      if (status === "active") return "green";
      else return "red";
    },
  },
};
</script>
